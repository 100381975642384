<template>
    <div style="">
        <a-card title="管网考核" :bordered="false" style="">
            <div class="table-page-search-wrapper">
                <a-form :form="form" layout="inline">
                    <a-row :gutter="[24, 0]">
                        <a-col :xxl="6" :xl="8" :lg="12" :sm="24">
                            <a-form-item label="指标类型：">
                                <a-select
                                        style=""
                                        placeholder="请选择"
                                        v-model="queryParam.zhibiao"
                                >
                                    <a-select-option v-for="item in zhibiaos" :key="item.id">
                                        {{item.name}}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :xxl="6" :xl="8" :lg="12" :sm="24">
                            <a-form-item no-prop label="比对层级">
                                <a-select
                                        style=""
                                        placeholder="请选择"
                                        v-model="queryParam.lv"
                                >
                                    <a-select-option v-for="item in levels" :key="item.id">
                                        {{item.name}}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :xxl="6" :xl="8" :lg="12" :sm="24" v-if="queryParam.lv == 5">
                            <a-form-item no-prop label="水厂">
                                <a-select
                                        style=""
                                        placeholder="请选择"
                                        v-model="queryParam.wfid"
                                >
                                    <a-select-option v-for="item in waterfactorys" :key="item.id">
                                        {{item.name}}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :xxl="6" :xl="8" :lg="12" :sm="24">
                            <a-form-item no-prop label="评估时段">
                                <a-range-picker v-model="range"/>
                            </a-form-item>
                        </a-col>

                        <a-col :xxl="6" :xl="8" :lg="12" :sm="24">
              <span
                      class="table-page-search-submitButtons"
              >
                <a-button type="primary" @click="handleQuery">查询</a-button>
                  <!--<a-button style="margin-left: 8px" @click="resetQuery">重置</a-button>-->
              </span>
                        </a-col>
                    </a-row>
                </a-form>
            </div>
        </a-card>
        <a-card title="查询结果" :bordered="false" style="margin-top: 5px;">
            <div slot="extra">
                <a-button type="primary" style="margin-left:10px;" @click="exportReport">
                    导出数据
                </a-button>
            </div>
            <a-spin :spinning="confirmLoading">
                <a-select v-model="defaultOption" style="width: 150px;margin-bottom: 20px;" @change="handleOptionChange">
                    <a-select-option v-for="item in barOptions" :value="item.id" >
                        {{item.name}}
                    </a-select-option>
                </a-select>
                <div style="height:300px;" ref="chart"></div>
            </a-spin>
        </a-card>
        <a-card>
            <a-table
                    row-key="index"
                    :columns="columns"
                    :dataSource="data"
                    :pagination="false"
                    :loading="confirmLoading"
                    @change="handleTableChange"
            >
                <a slot="leakPercent" slot-scope="text, record">{{
                    text
                    }}%</a>
                <template slot="index" slot-scope="text, record">
                    <div>{{ text }}</div>
                </template>
                <template slot="time" slot-scope="text, record">
                    <div>{{ record.start }} - {{record.end}}</div>
                </template>
            </a-table>
        </a-card>
    </div>
</template>

<script>
  import moment from 'moment'
  import * as echarts from "echarts";
  import {getAreaParam,findWaterfactoryByRegion,monitorList,groupList,pipeCheck,pipeCheckExprot,waterfactoryTree,connections} from "@/api/monitor/index";
  import { export_json_to_excel } from '@/excel/Export2Excel'
  const publicColums = [
    {
      dataIndex: "index",
      title: "序号",
      align: "center",
      scopedSlots: { customRender: "index" }
    },
    {
      dataIndex: "name",
      title: "监测点",
      align: "center"
    },
    // {
    //   dataIndex: "gname",
    //   title: "监测管井",
    //   align: "center"
    // },
    // {
    //   dataIndex: "time",
    //   title: "时间",
    //   align: "center"
    // },
    {
      dataIndex: "c3",
      title: "水压报警数",
      align: "center",
      sorter: (a, b) => a.c3 - b.c3,
    },
    {
      dataIndex: "c2",
      title: "流量报警数",
      align: "center",
      sorter: (a, b) => a.c2 - b.c2,
    },
    {
      dataIndex: "c",
      title: "报警总数",
      align: "center",
      sorter: (a, b) => a.c - b.c,
    }
  ];
  export default {
    name: "pipecheck",
    data() {
      return {
        form: this.$form.createForm(this),
        confirmLoading: false,
        columns: publicColums,
        data: [],
        pagination: {
          size: "",
          current: 1,
          pageSize: 10,
          total: 0,
          showSizeChanger: true,
          showLessItems: true,
          pageSizeOptions: ["5", "10", "20", "30", "50"],
          onShowSizeChange: (current, pageSize) => {
            this.pageSize = pageSize;
          },
          showTotal: total => `共${total}条`
        },
        tree: [],
        cascaderDefault: [],
        connections: [],
        fromMonitors: [],
        toMonitors:[],
        queryParam: { pageNum: 1, pageSize: 10,cityId: -1, wfid: 1,orderBy: '', zhibiao: 0, lv: 5,start: moment(), end: moment() },
        years: [2021],
        init: false,
        zhibiaos: [{id:0,name:'全部'},{id:3,name:'水压',title:'水压(MPa)'},{id:2,name:'流量',title:'流量(m³)'}],
        levels: [{id:3,name:'区县'},{id:4,name:'水厂'},{id:5,name:'监测点'}],
        barOptions: [{id:0,name:'报警总数'},{id:3,name:'水压报警数'},{id:2,name:'流量报警数'}],
        monitors: [],
        groups: [],
        range: [moment(),moment()],
        myChart: null,
        defaultOption: 0,
        waterfactorys: [],
        level: 0
      };
    },
    created() {
      let param = getAreaParam();
      this.level = param.level
      this.queryParam.cityId = param.cityId
      let idx = -1
      for (let i = 0; i < this.levels.length; i++) {
        if(this.level >= this.levels[i].id){
          idx = i
          break
        }
      }
      if(idx > -1){
        this.levels.splice(0,idx + 1)
      }

      findWaterfactoryByRegion(param).then(r=>{
        this.waterfactorys = r.data
      })
      this.fetchList()
    },
    mounted() {
      window.addEventListener('resize', this.resize)
    },
    watch:{

    },
    methods: {
      resize () {
        if (this.myChart) {
          this.myChart.resize()
        }
      },
      handleOptionChange(val){
        console.log(val)
        let dataindex = val == 0 ? 'c'  : 'c' + val
        const list = this.data
        const len = list.length
        let xdata = []
        let seriesData = []
        for (let i = 0; i < len; i++) {
          list[i].index = i + 1
          xdata.push(list[i].name)
          seriesData.push(list[i][dataindex])
        }
        this.initChart(xdata,seriesData)
      },
      getTreeDefaultValue(tree){
        let arr = []
        let first = tree[0]
        arr.push(first.id)
        while (first.children && first.children.length > 0){
          arr.push(first.children[0].id)
          first = first.children[0]
        }
        return arr
      },
      fetchList(){
        this.confirmLoading = true
        const param = Object.assign({}, this.queryParam)
        for (let i = 0; i < this.levels.length; i++) {
          if(this.levels[i].id == param.lv){
            this.columns[1].title = this.levels[i].name
            break
          }
        }
        param.start = moment(this.range[0]).format('YYYY-MM-DD')
        param.end = moment(this.range[1]).format('YYYY-MM-DD')
        pipeCheck(param).then(r=>{
          const data = r.data
          const list = data.list
          const len = list.length
          let xdata = []
          let seriesData = []
          let dataindex = param.zhibiao == 0 ? 'c' : 'c' + param.zhibiao
          this.defaultOption = param.zhibiao
          for (let i = 0; i < len; i++) {
            list[i].index = i + 1
            xdata.push(list[i].name)
            seriesData.push(list[i][dataindex])
          }

          this.data = list
          this.initChart(xdata,seriesData)
          this.confirmLoading = false
        }).catch(err =>{
          console.log(err)
          this.confirmLoading = false
        })
      },

      handleTableChange (pagination, filters, sorter) {
        console.log('Various parameters', pagination, filters, sorter)
        this.queryParam.pageNum = pagination.current
        this.queryParam.pageSize = pagination.pageSize
        this.fetchList()
      },
      onCascaderChange(value){
        console.log('onCascaderChange',value)
        const wfid = value[value.length - 1]
        this.queryParam.wfid = wfid
      },
      getZhibiaoTitle(zhibiao){
        for (let i = 0; i < this.zhibiaos.length; i++) {
          if(zhibiao === this.zhibiaos[i].id){
            return this.zhibiaos[i].title
          }
        }
        return ''
      },
      initChart(xAxisData,data) {
        var chartDom = this.$refs.chart;
        var myChart = echarts.init(chartDom);
        this.myChart = myChart
        var option;
        option = {
          grid:{
            top: 30,
            bottom:30,
            left:45,
            right:30
          },
          color:[
            'rgb(86,175,255)'
          ],
          tooltip: {
            trigger: "axis",
            // formatter: function (params) {
            //   console.log('params',params)
            //
            //   return ''
            // }
          },
          xAxis: {
            type: "category",
            data: xAxisData
          },
          yAxis: {
            type: "value",
            name: "报警次数"
          },
          // dataZoom: [{
          //         show: true,
          //         realtime: true,
          //         start: 30,
          //         end: 70,
          //         xAxisIndex: [0, 1]
          //     },
          //     {
          //         type: 'inside',
          //         realtime: true,
          //         start: 30,
          //         end: 70,
          //         xAxisIndex: [0, 1]
          //     }
          // ],
          series: [
            {
              type: "bar",
              barMaxWidth: 80,
              data: data,
              itemStyle: {
                normal: {
                  label: {
                    formatter: "{c}" + "次",
                    show: true,
                    position: "top",
                    textStyle: {
                      fontWeight: "bolder",
                      fontSize: "12",
                      color: "rgb(89,175,255)"
                    }
                  },
                }
              }
            }
          ],
          // dataZoom: [
          //   {
          //     id: 'dataZoomX',
          //     type: 'inside',
          //     xAxisIndex: [0],
          //     filterMode: 'none',
          //     start: 0,
          //     end: 50
          //   }
          // ]
        };
        myChart.setOption(option,true);
      },
      handleQuery () {
        this.queryParam.pageNum = 1
        this.fetchList()
      },
      resetQuery () {
        this.fetchList()
      },
      exportReport() {
          let list = this.data
          if(list){
            const len = list.length
            for (let i = 0;i < len;i++) {
              list[i].index = i + 1
            }
            let name = ''
            for (let i = 0; i < this.levels.length; i++) {
              if(this.levels[i].id == this.queryParam.lv){
                name = this.levels[i].name
                break
              }
            }
            const tHeader = ['序号', name, '流量报警数', '水压报警数','报警总数']
            const filterVal = ['index', 'name', 'c2','c3', 'c']
            const cols = [{wpx:40},{wpx:150},{wpx:110},{wpx:110},{wpx:110}]
            const data = this.formatJson(filterVal, list)
            export_json_to_excel(tHeader, data, '报警数列表',cols)
          }
      },
      formatJson(filterVal, jsonData) {
        return jsonData.map(v => filterVal.map(j => v[j]))
      },
    }
  };
</script>

<style scoped>
    .flex-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
</style>
